import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueScrollTo from "vue-scrollto";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Cookie Law
import CookieLaw from "vue-cookie-law";
Vue.component("cookie-law", CookieLaw);
Vue.use(CookieLaw);

Vue.use(VueGlide);
Vue.use(require("vue-moment"));
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
