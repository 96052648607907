<template>
  <div>
    <b-row align-h="center" class="container-top text-center">
      <b-col cols="8">
        <div class="header howitworks-header">Hur det fungerar</div>
      </b-col>
    </b-row>
    <b-row align-h="center" class="container-bottom text-start">
      <b-col cols="10">
        <div class="card-container">
          <b-card class="outer-card outer-card-shadow rounded-0 border-0">
            <b-row align-h="center">
              <b-col class="inner-card">
                <b-card class="rounded-0 border-0">
                  <div class="text-center">
                    <b-img
                      class="icon-square"
                      fluid
                      alt="consult icon"
                      :src="require('../assets/icons/consult-icon.svg')"
                    ></b-img>
                  </div>
                  <div class="number-vertical">
                    01
                  </div>
                  <div class="vertical-line text-font">
                    <div class="steps-card-title">
                      BOKA KONSULTATION
                    </div>
                    Boka en konsultation och välj mellan kost, träning eller 
                    fullständig coaching. Om den tjänst du behöver inte passar 
                    in i dessa tre kategorier, kontakta mig så kan vi diskutera
                    vad det är du letar efter.

                    <!-- You will be asked
          to fill in a few details about yourself for us to get started/get to
          know you/know a little about your goals. -->
                  </div>
                </b-card>
              </b-col>
              <b-col class="inner-card">
                <b-card class="rounded-0 border-0">
                  <div class="text-center">
                    <b-img
                      class="icon-square"
                      fluid
                      alt="discuss icon"
                      :src="require('../assets/icons/discuss-icon.svg')"
                    ></b-img>
                  </div>
                  <div class="number-vertical">
                    02
                  </div>
                  <div class="vertical-line text-font">
                    <div class="steps-card-title">
                      DISKUTERA DINA MÅL
                    </div>
                    Du kommer att bli kontaktad under din bokade konsultationstid 
                    för att diskutera dina mål och hur de kan uppnås.
                    <!-- This is a free consultation and there are no obligations to sign up
          for a program afterwards. -->
                  </div>
                </b-card>
              </b-col>
              <b-col class="inner-card">
                <b-card class="rounded-0 border-0">
                  <div class="text-center">
                    <b-img
                      class="icon-square"
                      fluid
                      alt="signup icon"
                      :src="require('../assets/icons/signup-icon.svg')"
                    ></b-img>
                  </div>
                  <div class="number-vertical">
                    03
                  </div>
                  <div class="vertical-line text-font">
                    <div class="steps-card-title">
                      ANMÄLAN &amp; BETALNING
                    </div>
                    Om du har bestämt dig för något av alternativen och betalningen är 
                    genomförd, kommer jag att börja arbeta på ditt program som är utformat 
                    utifrån de mål och planer som vi diskuterat under konsultationen.
                    <!-- "Based
          on this fitness assessment, I consider a client’s strengths,
          weaknesses and any personal limitations, preferences or medical
          considerations that may be applicable. This allows me to provide each
          client with the most effective and safe programme to achieve their
          desired results." "I will sit down with the information you’ve given
          me and create your bespoke training program." -->
                  </div>
                </b-card>
              </b-col>
              <b-col class="inner-card">
                <b-card class="rounded-0 border-0">
                  <div class="text-center">
                    <b-img
                      class="icon-square"
                      fluid
                      alt="receive icon"
                      :src="require('../assets/icons/receive-icon.svg')"
                    ></b-img>
                  </div>
                  <div class="number-vertical">
                    04
                  </div>
                  <div class="vertical-line text-font">
                    <div class="steps-card-title">
                      FÅ DITT PROGRAM
                    </div>
                    Inom 3 dygn får du ditt program och kan komma igång! När du
                    läst igenom programmet kan du kontakta mig med frågor om 
                    någonting skulle vara oklart eller om några ändringar behövs. 
                  </div>
                </b-card>
              </b-col>
              <b-col class="inner-card">
                <b-card class="rounded-0 border-0">
                  <div class="text-center">
                    <b-img
                      class="icon-square"
                      fluid
                      alt="analyse icon"
                      :src="require('../assets/icons/analyse-icon.svg')"
                    ></b-img>
                  </div>
                  <div class="number-vertical">
                    05
                  </div>
                  <div class="vertical-line text-font">
                    <div class="steps-card-title">
                      ANALYSERA &amp; FÖLJA UPP
                    </div>
                    Vi kommer ha veckovisa avstämningar, analysera hur det går och implementera 
                    justeringar mot underlag av resultaten. Vi kommer att titta på hur veckan gått,
                    hur det känts och vad vi kan göra för att få kommande vecka att gå ännu bättre 
                    i fråga om resultat och hållbarhet.
                  </div>
                </b-card>
              </b-col>
              <b-col class="inner-card text-center" align-self="center">
                <b-card class="rounded-0 border-0">
                  <b-button
                    pill
                    class="btn-learnmore text-nowrap"
                    v-on:click="$router.push('howitworks')"
                    >LÄS MER</b-button
                  >
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
};
</script>

<style scoped>
.steps-card-title {
  padding-top: 10px;
  padding-bottom: 15px;
  color: #c57b35;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
}

.btn-learnmore {
  padding: 15px 30px;
  background-color: #c57b35;
  border: none;
  font-weight: 600;
  transition: 0.5s;
  text-transform: uppercase;
  color: white;
}

.btn-learnmore:hover {
  border: 1px solid #c57b35;
  background-color: transparent;
  color: black;
  letter-spacing: 1px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container-top {
  padding-top: 70px;
}

.container-bottom {
  padding-bottom: 2em;
}

.header:after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 12%;
  border: 2px solid black;
  display: block;
  border-color: #c57b35;
}

.icon-square {
  height: 141px;
  margin-bottom: 10px;
}

.inner-card {
  flex: 0 1 380px;
}

.number-vertical {
  writing-mode: vertical-rl;
  font-size: 48px;
  color: #c57b35;
  float: left;
  width: 10%;
}

.outer-card {
  padding-bottom: 80px;
  flex: 0 1 1520px;
}

.outer-card-shadow:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 1px;
  height: 70%;
  z-index: -1;
  transform: scale(1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.vertical-line {
  border-left: 1px solid #c57b35;
  padding-left: 12px;
  float: right;
  width: 90%;
}

/* .left {
  position: relative;
  float: left;
  width: 10%;
  margin-left: -10%;
  padding: 0;
  height: 100%;
}

.right {
  position: relative;
  float: right;
  width: 90%;
  margin: 0;
  padding: 0;
  height: 100%;
  border-left: 1px solid black;
} */

/* .shadow {
  -webkit-box-shadow: 43px 12px 113px 31px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 43px 12px 113px 31px rgba(0, 0, 0, 0.35);
  box-shadow: 43px 12px 113px 31px rgba(0, 0, 0, 0.35);
} */
</style>
