<template>
  <div id="main">
    <b-container fluid>
      <b-row align-h="center" class="text-center success-icon-row">
        <b-col align-self="center">
          <b-img
            style="height: 150px;"
            fluid
            alt="success icon"
            :src="require('../assets/icons/success-tick.svg')"
          ></b-img>
        </b-col>
      </b-row>
      <b-row align-h="center" class="text-center">
        <b-col>
          <div class="success-header">Payment successful</div>
        </b-col>
      </b-row>

      <b-row align-h="center" class="text-center text-font">
        <b-col cols="6">
          <div>
            Thank you! Your subscription has been successful. A confirmation
            email will be sent to
            <span class="semi-strong-font">{{
              orderDetails.customer.email
            }}</span>
            shortly. <br /><span class="semi-strong-font"
              >COACHINGBYVIKTOR</span
            >
            will appear on your bank statement.
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="text-center text-font" cols="6">
          <hr />
          <div class="semi-strong-font payment-detail-header">
            PAYMENT DETAILS
          </div>
          <b-row align-h="between" class="payment-details">
            <b-col cols="12" md="6" class="payment-details-left">
              <div class="semi-strong-font">
                Order date
              </div>
            </b-col>
            <b-col cols="12" md="6" class="payment-details-right">
              {{
                orderDetails.customer.created | moment("MMM DD, YYYY, h:mm a")
              }}
            </b-col>
          </b-row>
          <b-row align-h="between" class="payment-details">
            <b-col cols="12" md="6" class="payment-details-left">
              <div class="semi-strong-font">
                Payment amount
              </div>
            </b-col>
            <b-col cols="12" md="6" class="payment-details-right">
              {{ orderDetails.amount_total / 100 }}kr
            </b-col>
          </b-row>
          <b-row align-h="between" class="payment-details-last">
            <b-col cols="12" md="6" class="payment-details-left">
              <div class="semi-strong-font">
                Order ref
              </div>
            </b-col>
            <b-col cols="12" md="6" class="payment-details-right">
              {{ orderDetails.customer.id }}
            </b-col>
          </b-row>
          <hr />
          <div class="home-link">
            <a href="https://coachingbyviktor.com">Go back to home page</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Api } from "@/Api";

export default {
  name: "success",
  data() {
    return {
      orderDetails: "",
    };
  },
  mounted() {
    console.log("Mounted");

    var params = new URLSearchParams(window.location.search);
    var id = params.get("id");

    console.log("did it work?" + id);

    Api.get("/checkout-session?id=" + id)
      .then((response) => {
        this.orderDetails = response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
</script>

<style scoped>
.home-link {
  padding-top: 24px;
  padding-bottom: 70px;
}

.payment-detail-header {
  padding-top: 24px;
  padding-bottom: 24px;
}

.payment-details-left {
  text-align: left;
}

.payment-details-right {
  text-align: right;
}

.payment-details {
  padding-bottom: 16px;
}

.payment-details-last {
  padding-bottom: 24px;
}

.semi-strong-font {
  font-weight: 600;
}

.success-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 48px;
  color: #2b2b2b;
  padding-bottom: 40px;
}

.success-icon-row {
  padding-top: 70px;
  padding-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .payment-details-right {
    text-align: center;
  }

  .payment-details-left {
    text-align: center;
  }
}
</style>
