<template>
  <b-container fluid>
    <div id="links">
      <b-row class="text-center links-background" align-h="center">
        <b-col cols="10">
          <div class="links">
            <span style="padding: 0 0.6em"
              ><a v-b-modal.modal-1>Terms and Conditions</a></span
            >
            |
            <span style="padding: 0 0.6em"
              ><a v-b-modal.modal-2>Privacy Policy</a></span
            >
            |
            <span style="padding: 0 0.6em; cursor:pointer;"
              ><a @click="$emit('openConsentBanner')"
                >Cookie Preferences</a
              ></span
            >
            <b-modal
              id="modal-1"
              title="Terms and Conditions"
              size="xl"
              button-size="sm"
              scrollable
            >
              <terms-conditions />
            </b-modal>
            <b-modal
              id="modal-2"
              title="Privacy Policy"
              size="lg"
              button-size="sm"
              scrollable
            >
              <privacy-policy />
            </b-modal>
          </div>
          <div class="copyright">
            Copyright © 2024 Coaching by Viktor
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import PrivacyPolicy from "./PrivacyPolicy.vue";
import TermsConditions from "./TermsConditions.vue";

export default {
  name: "Links",
  components: {
    TermsConditions,
    PrivacyPolicy,
  },
};
</script>

<style scoped>
#links {
  font-size: 0.96em;
}

.copyright {
  padding: 0.7em 0 1.5em 0;
}

.links-background {
  padding-top: 2.5em;
  /* background-color: #032129; */
}

.links,
.copyright {
  color: black;
}

.links > span > a:hover {
  color: darkgoldenrod;
}
</style>
