<template>
  <div id="plans">
    <nav-bar-pages />
    <small-banner pagename="Plans" />
    <b-container fluid>
      <b-row
        align-h="center"
        class="text-center"
        style="padding-top: 70px; background-color: #f9fafa"
      >
        <b-col cols="10" class="product-header">
          Products
        </b-col>
      </b-row>
      <b-row
        align-h="center"
        class="text-center"
        style="padding-bottom: 3em; background-color: #f9fafa"
      >
        <b-col cols="10" class="text-font">
          There are three different plans to choose from. Training only,
          nutrition only or full coaching which includes both training and
          nutrition coaching. If you opted not to have a consultation, you will
          receive an email with a link that leads to a form. This form will ask
          for details regarding your goals, food preferences, training history,
          etc., so I have the necessary information to get started on your
          program!
        </b-col>
      </b-row>
      <b-row
        align-h="center"
        class="text-center cards-row"
        style="background-color: #f9fafa"
      >
        <b-col class="cards-col d-flex">
          <b-card
            @click="createTrainingSession()"
            tabindex="1"
            no-body
            class="rounded-1"
          >
            <b-card-body>
              <b-card-title class="price-card-subheader">Training</b-card-title>
              <b-card-text class="card-price">
                <span class="price-amount">999</span>
                <span class="price-currency">SEK </span
                ><span class="price-month">/ mån</span>
              </b-card-text>
              <b-card-text class="price-description text-list-font">
                <ul class="no-bullets">
                  <li>Personalised training program</li>
                  <li>Weekly check ins and feedback</li>
                  <li>Results based adjustments</li>
                  <li>Unlimited support with direct access to me</li>
                </ul>
              </b-card-text>
            </b-card-body>
            <div
              class="align-self-end align-self-center mb-4 mt-auto btn-responsive-inner text-nowrap"
              @click="buttonClicked(training)"
            >
              {{ buttonText.training }}
            </div>
          </b-card>
        </b-col>
        <b-col class="cards-col d-flex">
          <b-card
            @click="createFCSession()"
            tabindex="1"
            no-body
            class="rounded-1"
          >
            <b-card-body>
              <b-card-title class="price-card-subheader"
                >Full Coaching<br />Training &amp; Nutrition</b-card-title
              >
              <b-card-text class="card-price">
                <span class="price-amount">1599</span>
                <span class="price-currency">SEK </span
                ><span class="price-month">/ mån</span>
              </b-card-text>
              <b-card-text class="price-description text-list-font">
                <ul class="no-bullets">
                  <li>Personalised nutrition and training programs</li>
                  <li>Weekly check ins and feedback</li>
                  <li>Results based adjustments</li>
                  <li>Unlimited support with direct access to me</li>
                </ul>
              </b-card-text>
            </b-card-body>
            <div
              class="align-self-end align-self-center mb-4 mt-auto btn-responsive-inner text-nowrap"
              @click="buttonClicked(fullCoaching)"
            >
              {{ buttonText.fullCoaching }}
            </div>
          </b-card>
        </b-col>
        <b-col class="cards-col d-flex">
          <b-card
            @click="createNutritionSession()"
            tabindex="1"
            no-body
            class="rounded-1"
          >
            <b-card-body>
              <b-card-title class="price-card-subheader"
                >Nutrition</b-card-title
              >
              <b-card-text class="card-price">
                <span class="price-amount">1299</span>
                <span class="price-currency">SEK </span
                ><span class="price-month">/ mån</span>
              </b-card-text>
              <b-card-text class="price-description text-list-font">
                <ul class="no-bullets">
                  <li>Personalised nutrition program</li>
                  <li>Based on your food preferences</li>
                  <li>Weekly check ins</li>
                  <li>Results based adjustments</li>
                  <li>Unlimited support with direct access to me</li>
                </ul>
              </b-card-text>
            </b-card-body>
            <div
              class="align-self-end align-self-center mb-4 mt-auto btn-responsive-inner text-nowrap"
              @click="buttonClicked(nutrition)"
            >
              {{ buttonText.nutrition }}
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="selectedPrice"
        align-h="center"
        class="text-center selected-price-display"
        style="background-color: #f9fafa"
      >
        <b-col>
          <div>Price: {{ selectedPrice }} sek / month</div>
          <div class="agreement">
            <b-form-checkbox
              class="checkbox"
              id="checkbox-1"
              v-model="status"
              name="checkbox-1"
              value="accepted"
              unchecked-value=""
            >
            </b-form-checkbox>
            <div class="agreement-text">
              I agree to the
              <a v-b-modal.modal-1><u>Terms and Conditions</u></a>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row
        align-h="center"
        v-if="showError"
        class="text-center errorMessage"
        style="background-color: #f9fafa"
      >
        <b-col>{{ errorMessage }}</b-col>
      </b-row>
      <b-row
        align-h="center"
        class="text-center payment-button-row"
        style="background-color: #f9fafa"
      >
        <b-col>
          <stripe-checkout
            ref="checkoutRef"
            :pk="publishableKey"
            :session-id="sessionId"
          >
            <template slot="checkout-button">
              <b-button
                pill
                class="align-self-end align-self-center mb-3 mt-auto btn-responsive text-nowrap"
                @click="
                  status
                    ? $refs.checkoutRef.redirectToCheckout()
                    : showErrorTrue()"
                >Go to Payment</b-button
              >
            </template>
          </stripe-checkout>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Api } from "@/Api";
import { StripeCheckout } from "vue-stripe-checkout";
import NavBarPages from "../components/NavBar-Pages.vue";
import SmallBanner from "../components/SmallBanner.vue";

export default {
  name: "plans",
  components: {
    StripeCheckout,
    SmallBanner,
    NavBarPages,
  },
  mounted() {
    console.log("Reached plans page");
    console.log(this.sessionId);
  },
  data() {
    return {
      //publishableKey:
      //  "pk_test_51HpCliEQ5bHCQ5EZIU2h5XnarnPnBF4kelYGfmu60IQxEDQ48720Wiliat4taf7kpWIHrIYY4Eez6uIrjM2Ik01L00kNogb8BA",
      publishableKey:
        "pk_live_51HpCliEQ5bHCQ5EZF89xskNXNd9FldKWMJ8Mjg2DMbaJVjEIV1ewEpzyeTLDfYiD6XBLfjtRDenZvq8aF3MGEFZr00A3vaEkzn",
      sessionId: "",
      selectedPrice: "",
      errorMessage: "You must read and agree to the terms and conditions",
      status: "",
      showError: "",
      buttonText: {
        training: "Choose",
        fullCoaching: "Choose",
        nutrition: "Choose",
      },
    };
  },
  methods: {
    showErrorTrue() {
      this.showError = true;
    },
    createTrainingSession() {
      this.buttonClicked("training");
      Api.post("/create-checkout-session-training").then((response) => {
        this.sessionId = response.data.id;
        this.selectedPrice = 700;
        // this.$router.push({name: 'plans', params: {id: this.sessionId}})
      });
    },
    createFCSession() {
      this.buttonClicked("fullCoaching");
      Api.post("/create-checkout-session-fullCoaching").then((response) => {
        this.sessionId = response.data.id;
        this.selectedPrice = 1200;
      });
    },
    createNutritionSession() {
      this.buttonClicked("nutrition");
      Api.post("/create-checkout-session-nutrition").then((response) => {
        this.sessionId = response.data.id;
        this.selectedPrice = 800;
      });
    },
    buttonClicked(option) {
      if (option == "training") {
        this.buttonText.training = "Chosen!";
        this.buttonText.fullCoaching = "Choose";
        this.buttonText.nutrition = "Choose";
      } else if (option == "fullCoaching") {
        this.buttonText.training = "Choose";
        this.buttonText.fullCoaching = "Chosen!";
        this.buttonText.nutrition = "Choose";
      } else if (option == "nutrition") {
        this.buttonText.training = "Choose";
        this.buttonText.fullCoaching = "Choose";
        this.buttonText.nutrition = "Chosen!";
      }
    },
  },
};
</script>

<style scoped>
.agreement {
  font-size: 14px;
  padding: 1em 0 0.5em 0;
}

.agreement-text {
  display: inline-block;
}

.btn-responsive {
  padding: 15px 30px;
  background-color: #c57b35;
  border: none;
  font-weight: 600;
  transition: 0.5s;
  text-transform: uppercase;
  color: white;
}

.btn-responsive:hover {
  border: 1px solid #c57b35;
  background-color: transparent;
  color: black;
  letter-spacing: 1px;
}

.btn-responsive-inner {
  padding: 15px 30px;
  background-color: #c57b35;
  border: none;
  border-radius: 30px;
  font-weight: 600;
  transition: 0.5s;
  text-transform: uppercase;
  color: white;
}

.btn-responsive-inner:hover {
  border: 1px solid #c57b35;
  background-color: transparent;
  color: black;
  letter-spacing: 1px;
}

.card {
  border-color: transparent;
  width: 100%;
}

.card:focus {
  border-color: #c57b35;
  outline: none !important;
  -webkit-box-shadow: 0px 0px 38px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 38px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 38px -19px rgba(0, 0, 0, 0.75);
}

.card:hover {
  border-color: #c57b35;
  cursor: pointer;
}

.card-price {
  color: #c57b35;
  padding-bottom: 10px;
  z-index: 2;
}

.cards-col {
  flex: 0 1 320px;
  padding-bottom: 2em;
}

.cards-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0 50px 0;
}

.checkbox {
  display: inline-block;
}

.errorMessage {
  color: red;
  font-size: 12px;
}

.payment-button-row {
  padding-top: 1em;
  padding-bottom: 3em;
}

.price-amount {
  font-weight: 600;
  font-size: 36px;
  z-index: 2;
}

.price-card-subheader {
  color: #2b2b2b;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  z-index: 2;
  padding-top: 20px;
}

.price-currency {
  font-size: 24px;
  font-weight: 600;
  z-index: 2;
}

.product-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
  color: #2b2b2b;
  padding-bottom: 30px;
}

.product-header:after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 5%;
  border: 2px solid black;
  display: block;
  border-color: #c57b35;
}

.selected-price-display {
  padding-top: 1em;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 1.2em;
}

.text-list-font {
  font-family: "Montserrat", sans-serif;
  color: #747474;
  font-size: 16px;
  z-index: 2;
}

ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.no-bullets li {
  padding-bottom: 10px;
}
</style>
