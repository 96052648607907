<template>
  <div id="home">
    <NavBar />
    <HeadBanner />
    <b-container fluid>
      <CoachingByViktor />
      <Services />
      <HowItWorks />
      <SelectPlan />
      <PricingInfo />
      <ContactForm />
      <AboutUs />
    </b-container>
    <Cta />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import HeadBanner from "@/components/HeadBanner";
import AboutUs from "@/components/AboutUs";
import PricingInfo from "@/components/PricingInfo";
import SelectPlan from "@/components/SelectPlan";
import ContactForm from "@/components/ContactForm";
import Services from "@/components/Services";
import CoachingByViktor from "@/components/CoachingByViktor";
import HowItWorks from "@/components/HowItWorks";
import Cta from "@/components/cta";

export default {
  name: "home",
  components: {
    NavBar,
    HeadBanner,
    AboutUs,
    PricingInfo,
    SelectPlan,
    ContactForm,
    Services,
    CoachingByViktor,
    HowItWorks,
    Cta,
  },
  data() {
    return {
      msg: "Hello",
    };
  },
};
</script>

<style>
@font-face {
  font-family: "Bellatrix";
  src: local("Bellatrix"),
    url(../fonts/bellatrix/Bellatrix.ttf) format("truetype");
}

@font-face {
  font-family: "High-Summit";
  src: local("High-Summit"),
    url(../fonts/high-summit/High-Summit.ttf) format("truetype");
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-family: "Montserrat", sans-serif;
}

.header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 48px;
  /* 40px seems to be ideal? */
  text-transform: uppercase;
  color: #2b2b2b;
  padding-bottom: 60px;
}

.header-with-description {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 48px;
  /* 40px seems to be ideal? */
  text-transform: uppercase;
  color: #2b2b2b;
  padding-bottom: 30px;
}

.subheader {
  color: #2b2b2b;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.text-font {
  font-family: "Montserrat", sans-serif;
  color: #414141;
  font-size: 16px;
  line-height: 28px;
}

.section-description {
  font-family: "Montserrat", sans-serif;
  padding-bottom: 48px;
  color: rgb(80, 80, 80);
  font-size: 16px;
  line-height: 26px;
}

@media screen and (max-width: 1024px) {
  .header {
    font-size: 2.6em;
  }
}

@media screen and (max-width: 768px) {
  .header,
  .header-with-description {
    font-size: 36px;
  }
}
</style>
