<template>
  <div id="plans-banner">
    <b-img
      class="bannerimage"
      :src="image"
      fluid
      alt="Responsive image"
    ></b-img>
    <b-container fluid class="slogan-container">
      <b-row class="text-center" align-v="center">
        <b-col class="plan-page-header">
          {{ pagename }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Banner from "@/assets/plans.jpg";

export default {
  name: "plansbanner",
  props: ["pagename"],
  data() {
    return {
      image: Banner,
    };
  },
};
</script>

<style scoped>
#plans-banner {
  background-color: black;
}

.bannerimage {
  object-fit: cover;
  height: 25rem;
  width: 100vw;
  /* opacity: 0.4; */
  /* box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.75); */
}

.plan-page-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 3.6em;
  letter-spacing: 0.02em;
}

/* .plan-page-header:after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 5%;
  border: 2px solid black;
  display: block;
  border-color: #c57b35;
} */

.slogan-container {
  top: 10rem;
  position: absolute;
  color: rgba(255, 255, 255, 0.904);
  opacity: 1;
}
</style>
