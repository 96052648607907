<template>
  <div id="coachingbyviktor">
    <b-row class="coachingbyviktor" align-h="center" align-v="center">
      <b-col class="nostretch-img cols-padding" lg="4">
        <b-img fluid :src="require('../assets/cbv-01.jpg')" />
      </b-col>
      <b-col class="cols-padding" lg="5" offset-lg="1" cols="10">
        <div class="cbv-header">Coaching by Viktor</div>
        <div class="text-font">
          Som coach strävar jag efter att göra det enkelt och bekvämt att göra en livsstilsförändring.
          Jag ser det som mitt jobb att hitta det tillvägagångssätt som fungerar bäst för dig, för att
          säkerställa att du når dina mål. Gillar du inte att laga mat varje dag? 
          Då ordnar jag en kostplan som bara kräver matlagning två gånger i veckan.
          Vill du ha färsklagade måltider varje dag? Perfekt, då ordnar jag en-måltids recept.
          <br />
          <br />
          Mitt främsta mål är att skapa ett program specifikt för dig, med hänsyn till omständigheter 
          och preferenser som är specifika för dig och ditt liv. För att göra det så enkelt som möjligt för 
          dig att hålla dig till programmet och nå dina mål. 
        </div>
        <div class="signature text-center">
          ViktorRosengren
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CoachingByViktor",
};
</script>

<style scoped>
.coachingbyviktor {
  padding-top: 140px;
  padding-bottom: 30px;
  background-color: white;
}

.cols-padding {
  padding-bottom: 40px;
}

.nostretch-img {
  flex: 0 1 420px;
}

.signature {
  padding-top: 0.6em;
  font-family: "High-Summit", sans-serif;
  font-weight: 400;
  font-size: 2.5em;
  color: rgba(0, 0, 0, 0.205);
  transform: rotate(-5grad);
  /* background-image: linear-gradient(to right, red, orange 50%, brown); */
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.404),
    rgba(0, 0, 0, 0.11)
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.cbv-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 48px;
  text-transform: uppercase;
  color: #2b2b2b;
  padding-bottom: 40px;
}

@media screen and (max-width: 1024px) {
  .cbv-header {
    font-size: 2.6em;
  }
}

@media screen and (max-width: 768px) {
  .cbv-header {
    font-size: 36px;
    text-align: center;
  }
}

/* .cbv-text {
  line-height: 26px;
  color: rgb(63, 63, 63);
  font-size: 16px;
} */
</style>
