<template>
  <div>
    <b-navbar class="fixed-top nav-bg" transparent toggleable="md" type="dark">
      <b-navbar-brand to="/" class="mb-0"
        ><b-button squared variant="outline" class="logo"
          >Coaching
          <p class="under-logo">by Viktor</p></b-button
        >
      </b-navbar-brand>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar-Pages",
};
</script>

<style scoped>
.navbar {
  min-height: 80px;
  /* border-bottom: 1px solid white; */
  margin-left: 50px;
  margin-right: 50px;
  position: absolute;
}

.btn-contact {
  background-color: #c57b35;
  border-radius: 25px;
  border: none;
  transform: translateY(20%);
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  white-space: nowrap;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  transition: 0.5s ease;
}

.btn-contact:hover {
  border: none;
  background-color: rgba(255, 255, 255, 0.9);
  color: #c57b35;
}

.btn-contact:focus {
  border: none;
  outline: none;
  background-color: #c57b35;
  color: rgba(255, 255, 255);
}

.btn-outline-light:hover {
  border-color: white;
}

.btn-outline:hover {
  color: white;
}

.contact-nav {
  margin-left: 40px;
  margin-right: 50px;
}

p {
  margin-bottom: 0;
}

.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-width: 0.15em;
  border-color: white;
  color: #c57b35;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: 0.5s ease;
}

.under-logo {
  font-size: 0.35em;
}

.nav-item {
  align-self: flex-end;
  /* width: 8em;
  font-family: "Oswald", sans-serif; */
  width: 8em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  white-space: nowrap;
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: #c57b35 !important;
}
</style>
