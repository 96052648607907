<template>
  <div id="cta">
    <b-img class="ctaimage" :src="image" fluid alt="Responsive image"></b-img>
    <b-container fluid class="cta-container">
      <b-row class="cta-row text-center" align-v="center">
        <b-col>
          <div class="cta-text">
            Påbörja din resa mot att nå dinal mål
          </div>
          <div>
            <b-button
              pill
              target="_blank"
              href="https://calendly.com/coachingbyviktor/consultation"
              class="btn-cta-request text-nowrap"
            >
              Boka Konsultation
            </b-button>
            <b-button
              pill
              v-on:click="$router.push('plans')"
              class="btn-cta-getstarted text-nowrap"
            >
              Kom Igång Direkt
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ctaimage from "@/assets/CTA.jpg";

export default {
  name: "Cta",
  data() {
    return {
      image: ctaimage,
    };
  },
};
</script>

<style scoped>
#cta {
  background-color: black;
}

.btn-cta-getstarted {
  padding: 15px 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  font-weight: 600;
  transition: 0.5s;
  text-transform: uppercase;
  color: #2b2b2b;
  margin-right: 15px;
  margin-left: 15px;
}

.btn-cta-getstarted:hover {
  border: 1px solid #c57b35;
  background-color: transparent;
  color: rgba(255, 255, 255);
  letter-spacing: 1px;
}

.btn-cta-request {
  padding: 15px 30px;
  background-color: #c57b35;
  border: none;
  font-weight: 600;
  transition: 0.5s;
  text-transform: uppercase;
  color: white;
  margin-right: 15px;
  margin-left: 15px;
}

.btn-cta-request:hover {
  border: 1px solid #c57b35;
  background-color: transparent;
  color: rgba(255, 255, 255);
  letter-spacing: 1px;
}

.ctaimage {
  object-fit: cover;
  height: 25rem;
  width: 100vw;
  opacity: 0.3;
  position: absolute;
}

.cta-container {
  opacity: 1;
  height: 25rem;
}

.cta-row {
  height: 100%;
}

.cta-text {
  font-size: 36px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
  padding-bottom: 45px;
}

@media screen and (max-width: 1024px) {
  .ctaimage {
    height: 20rem;
  }
  .cta-container {
    height: 20rem;
  }
  .cta-text {
    font-size: 24px;
  }
  .btn-cta-getstarted,
  .btn-cta-request {
    font-size: 15px;
    padding: 12px 28px;
  }
}

@media screen and (max-width: 768px) {
  .ctaimage {
    height: 18rem;
  }
  .cta-container {
    height: 18rem;
  }
  .btn-cta-getstarted,
  .btn-cta-request {
    margin-bottom: 5px;
  }
}
</style>
