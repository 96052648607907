<template>
  <div id="select-plan">
    <b-row align-h="center" class="select-plan-container text-center">
      <b-col cols="10" class="select-plan-inner-container">
        <div class="title-text">
          VET DU REDAN VAD DU VILL HA? 
        </div>
        <div class="description-text text-font">
          Vet du redan vilka tjänster du är intresserad av? Är du redo att kicka 
          igång direkt, utan konsultation? Klicka i så fall på knappen nedan för 
          att köpa en av mina tjänster och få ditt program inom 72 timmar! 
        </div>
        <b-button
          v-on:click="$router.push('plans')"
          pill
          class="btn-viewplans text-nowrap"
          >SE TJÄNSTER &amp; BÖRJA DIREKT</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "select-plan",
};
</script>

<style scoped>
.btn-viewplans {
  padding: 15px 30px;
  background-color: #c57b35;
  border: none;
  font-weight: 600;
  transition: 0.5s;
  text-transform: uppercase;
  color: white;
}

.btn-viewplans:hover {
  border: 1px solid #c57b35;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2b2b2b;
  letter-spacing: 1px;
}

.select-plan-container {
  margin-top: -150px;
  background-color: #032129;
  padding-top: 170px;
  padding-bottom: 70px;
}

.title-text {
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 40px;
  color: #c57b35;
}

.description-text {
  font-size: 16px;
  padding-bottom: 40px;
  color: white;
}

@media screen and (max-width: 768px) {
  .btn-viewplans {
    font-size: 15px;
    padding: 12px 28px;
  }
}
</style>
