<template>
  <div id="app">
    <router-view />
    <Footer @openConsentBanner="openConsentBanner()" />

    <cookie-law
      theme="custom"
      :buttonDecline="buttonDecline"
      buttonText="Accept"
      ref="cookie"
    >
      <div slot="message">
        This websites uses cookies to ensure you get the best experience on our
        website. By clicking "Accept" you consent to our Cookie Policy which can
        be found in our
        <a v-b-modal.modal-2><u>Privacy Policy</u></a
        >.
        <a target="_blank" href="https://www.cookiesandyou.com/">Learn more</a>
      </div>
      <div slot="buttonClass" />
    </cookie-law>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
export default {
  components: { Footer },
  name: "app",
  data() {
    return {
      buttonDecline: true,
    };
  },
  methods: {
    openConsentBanner() {
      this.$refs.cookie.isOpen = true;
    },
  },
};
</script>

<style scoped>
.Cookie--custom {
  height: 50px;
  font-size: 14px;
  background-color: rgb(12, 12, 12);
  color: rgba(255, 255, 255, 0.7);
  padding: 0 2em;
  align-self: center;
}

.Cookie--custom .Cookie__button {
  background-color: black !important;
}
</style>
