<template>
  <div id="services">
    <b-row
      align-h="center"
      class="text-center"
      style="background-color:#f9fafa"
    >
      <b-col cols="8">
        <div class="header service-header">Utbud</div>
      </b-col>
    </b-row>
    <b-row align-h="center" class="cards-row" style="background-color:#f9fafa">
      <b-col class="cards-col d-flex">
        <b-card no-body class="border-0 rounded-0">
          <b-card-body>
            <b-card-title class="subheader"
              ><b-img
                class="icon"
                fluid
                alt="checkbox icon"
                :src="require('../assets/icons/tickbox.svg')"
              ></b-img>
              Personligt Anpassade Scheman</b-card-title
            >
            <b-card-text class="text-font">
              Både tränings- och kostscheman kommer att utformas individuellt baserat 
              på konsultationsstilfället där vi kommer att arbeta tillsammans för att 
              skapa en hållbar plan som passar dig och dina mål. 
              <!-- "Your program will be created from
              scratch as your personal blueprint for success in fitnes. Putting
              an end to guesswork and wasted effort in your health and fitness
              pursuits" -->
            </b-card-text>
          </b-card-body>
          <!-- <a href="#" class="card-link">Card link</a>
          <b-link href="#" class="card-link">Another link</b-link> -->
        </b-card>
      </b-col>
      <b-col class="cards-col d-flex">
        <b-card no-body class="border-0 rounded-0">
          <b-card-body>
            <b-card-title class="subheader">
              <b-img
                class="icon"
                fluid
                alt="checkbox icon"
                :src="require('../assets/icons/tickbox.svg')"
              ></b-img>
              Träningsschema</b-card-title
            >
            <b-card-text class="text-font">
              Ditt träningsprogram kommer att utformas baserat på din tidigare erfarenhet,
              tillgång till utrustning, tidsåtaganden och målbild samtidigt som hänsyn 
              tas till eventuella skador du kan ha. 
              <!-- "Your custom meal plan for you calculated to the exact
              caloric and macronutrient intake for your goals with full recipes
              that are customisable" -->
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      align-h="center"
      class="cards-row"
      style="background-color:#f9fafa; padding-bottom: 70px;"
    >
      <b-col class="cards-col d-flex">
        <b-card no-body class="border-0 rounded-0">
          <b-card-body>
            <b-card-title class="subheader">
              <b-img
                class="icon"
                fluid
                alt="checkbox icon"
                :src="require('../assets/icons/tickbox.svg')"
              ></b-img>
              Kostplan</b-card-title
            >
            <b-card-text class="text-font">
              Du kommer att få en personlig kostplan bestående av fullständiga recept,
              skräddarsydda efter dina smaker, matpreferenser och eventuella allergier 
              du kan ha. Måltiderna kommer att beräknas till exakt kalori- och 
              makronäringsintag för dina mål med recept som går att anpassa. 

              <!-- "As one of my online training
              clients you will receive unlimited instant messaging support
              directly with me via my app."
              "Your Program includes an onboarding
              call to discuss your program and your expected results. Plus a
              monthly review call, weekly check-in and unlimited instant
              messaging support" "Every aspect of your training program will be
              fully explained beginning with an onboarding call to explain your
              program and the results I expect to see, as well as the
              methodology behind your unique plan." -->
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col class="cards-col d-flex">
        <b-card no-body class="border-0 rounded-0">
          <b-card-body>
            <b-card-title class="subheader">
              <b-img
                class="icon"
                fluid
                alt="checkbox icon"
                :src="require('../assets/icons/tickbox.svg')"
              ></b-img>
              Rapporteringsansvar &amp; Stöd</b-card-title
            >
            <b-card-text class="text-font">
              Jag kommer att följa dina framsteg och vi kommer att ha veckovisa 
              uppföljningar där vi analyserar och justerar ditt program för att 
              säkerställa att du får de bästa resultaten på ett sätt som är 
              hållbart för din livsstil. Du kommer också att få obegränsat med 
              stöd via meddelanden direkt till mig för att ställa frågor,
              ladda upp videor och få feedback.

              
              <!-- "As one of my online training
              clients you will receive unlimited instant messaging support
              directly with me via my app."
              "Your Program includes an onboarding
              call to discuss your program and your expected results. Plus a
              monthly review call, weekly check-in and unlimited instant
              messaging support" "Every aspect of your training program will be
              fully explained beginning with an onboarding call to explain your
              program and the results I expect to see, as well as the
              methodology behind your unique plan." -->
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<style scoped>
.card {
  height: 100%;
  width: 100%;
  /* padding-top: 0.4em; */
  background-color: rgba(255, 255, 255, 0.85);
  /* margin-bottom: 2em; */
}

.cards-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* .card-title {
  margin-top: 20px;
} */

.card-text {
  /* padding-top: 5px; */
  padding-left: 62px;
}

.cards-col {
  flex: 0 1 600px;
  padding-bottom: 24px;
}

.icon {
  height: 36px;
  padding-right: 20px;
}

.service-header {
  padding-top: 70px;
}

.header:after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 12%;
  border: 2px solid black;
  display: block;
  border-color: #c57b35;
}

.shadow {
  -webkit-box-shadow: 0px 0px 38px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 38px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 38px -19px rgba(0, 0, 0, 0.75);
}
</style>
