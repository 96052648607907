<template>
  <div id="aboutus">
    <b-row align-h="center" align-v="center" class="aboutus">
      <b-col md="4" class="nostretch-about-img about-cols-padding">
        <b-img fluid :src="require('../assets/about-viktor2.jpg')" />
      </b-col>
      <b-col class="about-cols-padding" md="5" offset-md="1" cols="10">
        <div class="about-header">
          OM MIG
        </div>
        <div class="text-font">
          Min resa började när jag som 15-åring för första gången klev in på ett gym. Jag kände direkt att 
          jag hade hittat något som var helt annorlunda än allt jag tidigare ägnat mig åt. Känslan av att
          kunna sätta upp egna mål och vara den ende som kan påverka resultatet var fantastisk. Jag
          utvecklade en drivkraft att lära mig så mycket jag bara kunde för att nå mina mål. Genom åren har 
          jag läst om och provat på en hel del olika tillvägagångssätt och i processen fått perspektiv på och 
          insikt i vad som är en bra investering av min tid och vad som inte är det. 
          <br />
          <br />
          Idag är jag licensierad personlig tränare och jag brinner för att hjälpa människor att förverkliga sin
          potential och nå nya höjder i sina fysiska strävanden. Jag har tagit den långa vägen med alla dess 
          fallgropar och det finns ingen anledning för dig att göra samma misstag som jag har gjort!
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped>
.aboutus {
  padding-top: 70px;
  padding-bottom: 30px;
}

.about-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 48px;
  text-transform: uppercase;
  color: #2b2b2b;
  padding-bottom: 40px;
}

.about-header:after {
  content: " ";
  position: absolute;
  margin-left: 85px;
  width: 100px;
  border: 2px solid black;
  display: block;
  border-color: #c57b35;
}

.about-cols-padding {
  padding-bottom: 40px;
}

.nostretch-about-img {
  flex: 0 1 420px;
}

@media screen and (max-width: 1024px) {
  .about-header {
    font-size: 2.6em;
  }
}

@media screen and (max-width: 768px) {
  .about-header:after {
    display: none;
  }
  .about-header {
    font-size: 36px;
    text-align: center;
  }
}
</style>
