<template>
  <div id="pricinginfo">
    <b-row class="select-plan-container">
      <b-col>
        <b-row align-h="center" class="text-center">
          <b-col cols="8">
            <div class="header-with-description">
              TJÄNSTER &amp; PRISER
            </div>
            <div class="section-description">
              Det finns tre olika tjänster att välja mellan. Endast träning,
              endast kost eller full coaching som inkluderar både träning 
              och kostcoachning. För att välja ett alternativ klickar du bara
              på knappen "begär konsultation" och bokar en tid för en 
              konsultation. Det finns inga förpliktelser att köpa en tjänst 
              efter konsultationen.
            </div>
          </b-col>
        </b-row>
        <div class="price-cards-container">
          <b-row align-h="center" class="text-center card-row-position">
            <b-col cols="10" md="5" lg="4" class="align-items-center d-flex">
              <b-card
                no-body
                class="rounded-0 d-flex"
                style="background-color:#2b2b2b"
              >
                <b-img
                  class="card-bg-img"
                  fluid
                  alt="consult icon"
                  :src="require('../assets/price-card-1.jpg')"
                ></b-img>
                <b-card-body class="d-flex flex-column">
                  <b-card-title class="price-card-subheader"
                    >Träning</b-card-title
                  >
                  <b-card-text class="card-price">
                    <span class="price-amount">999</span>
                    <span class="price-currency">SEK </span
                    ><span class="price-month">/ mån</span>
                  </b-card-text>
                  <b-card-text class="price-description text-list-font">
                    <ul class="no-bullets">
                      <li>15 min konsultation</li>
                      <li>Individanpassat träningsprogram</li>
                      <li>Veckovisa avstämningar och feedback </li>
                      <li>Resultatbaserade korrigeringar</li>
                      <li>Obegränsad support/direktkontakt med mig</li>
                    </ul>
                  </b-card-text>
                  <b-button
                    pill
                    class="align-self-end align-self-center mb-3 mt-auto btn-responsive text-nowrap"
                    target="_blank"
                    href="https://calendly.com/coachingbyviktor/consultation"
                    >Boka Konsultation</b-button
                  >
                </b-card-body>
              </b-card>
            </b-col>
            <b-col cols="10" md="5" lg="4" class="align-items-center d-flex">
              <b-card
                no-body
                class="rounded-0 d-flex card-mid"
                style="background-color:#252525"
              >
                <b-img
                  class="card-bg-img-low-opacity"
                  fluid
                  alt="consult icon"
                  :src="require('../assets/price-card-2.png')"
                ></b-img>
                <b-card-body class="d-flex flex-column">
                  <b-card-title class="price-card-subheader"
                    >Heltäckande Coachning</b-card-title
                  >
                  <b-card-text class="card-price">
                    <span class="price-amount">1599</span>
                    <span class="price-currency">SEK </span
                    ><span class="price-month">/ mån</span>
                  </b-card-text>
                  <b-card-text class="price-description text-list-font">
                    <ul class="no-bullets">
                      <li>15 min konsultation</li>
                      <li>Individanpassat träningsprogram och kostschema</li>
                      <li>Veckovisa avstämningar och feedback</li>
                      <li>Resultatbaserade korrigeringar</li>
                      <li>Obegränsad support/direktkontakt med mig</li>
                    </ul>
                  </b-card-text>
                  <b-button
                    pill
                    class="align-self-end align-self-center mb-3 mt-auto btn-responsive text-nowrap"
                    target="_blank"
                    href="https://calendly.com/coachingbyviktor/consultation"
                    >Boka konsultation</b-button
                  >
                </b-card-body>
              </b-card>
            </b-col>
            <b-col cols="10" md="5" lg="4" class="align-items-center d-flex">
              <b-card
                no-body
                class="rounded-0 d-flex"
                style="background-color:#252525"
              >
                <b-img
                  class="card-bg-img-low-opacity"
                  fluid
                  alt="consult icon"
                  :src="require('../assets/price-card-3.jpg')"
                ></b-img>
                <b-card-body class="d-flex flex-column">
                  <b-card-title class="price-card-subheader"
                    >Kostprogram</b-card-title
                  >
                  <b-card-text class="card-price">
                    <span class="price-amount">1299</span>
                    <span class="price-currency">SEK </span
                    ><span class="price-month">/ mån</span>
                  </b-card-text>
                  <b-card-text class="price-description text-list-font">
                    <ul class="no-bullets">
                      <li>15 minuters konsultation</li>
                      <li>Individanpassat kostschema</li>
                      <li>Baserat på dina matpreferenser</li>
                      <li>Veckovisa avstämningar och feedback</li>
                      <li>Resultatbaserade korrigeringar</li>
                      <li>Obegränsad support/direktkontakt med mig</li>
                    </ul>
                  </b-card-text>
                  <div class="nutrition-card-button">
                    <b-button
                      pill
                      class="align-self-end align-self-center mb-3 mt-auto btn-responsive text-nowrap"
                      target="_blank"
                      href="https://calendly.com/coachingbyviktor/consultation"
                      >Boka konsultation</b-button
                    >
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "PricingInfo",
};
</script>

<style scoped>
.price-card-subheader {
  color: rgba(255, 255, 255, 0.9);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  z-index: 2;
}

.card-bg-img {
  height: 100%;
  width: 100%;
  /* height: 25rem;
  width: 100%; */
  opacity: 0.2;
  position: absolute;
  object-fit: cover;
}

.card-bg-img-low-opacity {
  height: 100%;
  width: 100%;
  /* height: 25rem;
  width: 100%; */
  opacity: 0.1;
  position: absolute;
  object-fit: cover;
}

.select-plan-container {
  background: linear-gradient(to bottom, #f9fafa 0%, white 100%);
  overflow: hidden;
  padding-top: 70px;
}

.nutrition-card-button {
  padding-bottom: 30px;
  z-index: 2;
}

.header-with-description:after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 12%;
  border: 2px solid black;
  display: block;
  border-color: #c57b35;
}

.btn-responsive {
  transition: 0.5s;
  text-transform: uppercase;
  padding: 15px 30px;
  background-color: #c57b35;
  border: none;
  font-weight: 600;
  color: white;
  font-size: 14px;
  z-index: 2;
}

.btn-responsive:hover {
  border: 1px solid #c57b35;
  background-color: transparent;
  color: white;
  letter-spacing: 1px;
}

.card {
  height: 90%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: block;
  margin: auto;
  margin-bottom: 2em;
  -webkit-box-shadow: 0px 4px 30px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 30px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 30px -5px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
  border-color: transparent;
}

.card-mid {
  height: 100%;
}

.card:hover {
  border: 1px solid #c57b35;
}

.card-price {
  color: #c57b35;
  padding-bottom: 10px;
  z-index: 2;
}

.card-row-position {
  flex: 0 1 1060px;
  padding-bottom: 86px;
}

.card-title {
  padding-bottom: 5px;
  padding-top: 40px;
}

.price-amount {
  font-weight: 600;
  font-size: 36px;
  z-index: 2;
}

.price-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.price-currency {
  font-size: 24px;
  font-weight: 600;
  z-index: 2;
}

.price-description {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.price-month {
  font-size: 24px;
}

.text-list-font {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  z-index: 2;
}

ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.no-bullets li {
  padding-bottom: 10px;
}

/* @media screen and (max-width: 1024px) {
  .card {
    height: 95%;
    width: 110%;
  }
} */

@media screen and (max-width: 991px) {
  .card {
    height: 95%;
    width: 100%;
  }
  .nutrition-card-button {
    padding-bottom: 0;
  }
}
</style>
