<template>
  <div id="faq">
    <hr />

    <b-row v-on:click="show = !show">
      <b-col cols="11" class="question">
        <div>
          {{ question.question }}
        </div>
      </b-col>
      <b-col cols="1" class="question text-right">
        <transition name="fade" mode="out-in">
          <div
            v-if="show"
            key="collapse"
            style="font-size: 34px; float: right !important; padding-right: 10px; margin-top: -14px; cursor: pointer;"
          >
            -
          </div>
          <div v-else key="expand" class="expand">+</div>
        </transition>
      </b-col>
    </b-row>

    <transition name="fade">
      <b-row v-if="show" class="text-font" style="padding-top: 10px;">
        <b-col cols="11">
          <p>
            {{ question.answer }}
          </p>
          <p v-if="question.answer2">{{ question.answer2 }}</p>
        </b-col>
      </b-row>
    </transition>
    <!-- 
    <a v-on:click="show = !show" style="font-weight:600; color:#2b2b2b;">
      <div class="question">
        {{ question.question }}
      </div>
      <transition name="fade" mode="out-in">
        <span
          v-if="show"
          key="collapse"
          style="font-size: 34px; float: right !important; padding-right: 15px; margin-top: -14px; cursor: pointer;"
          >-</span
        >
        <span v-else key="expand" class="expand">+</span>
      </transition>
    </a>

    <transition name="fade">
      <div v-if="show" class="text-font" style="padding-top: 10px;">
        <p>
          {{ question.answer }}
        </p>
        <p v-if="question.answer2">{{ question.answer2 }}</p>
      </div>
    </transition> -->
  </div>
</template>

<script>
export default {
  name: "faq",
  props: ["question"],
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.expand {
  float: right !important;
  padding-right: 15px;
  font-size: 26px;
  cursor: pointer;
  margin-top: -5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

hr {
  margin: 12px 0 25px 0;
}

.question {
  font-weight: 600;
  color: #2b2b2b;
  cursor: pointer;
}
</style>
