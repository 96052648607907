<template>
  <div>
    <strong>AGREEMENT TO TERMS</strong><br />
    These Terms of Use constitute a legally binding agreement made between you,
    whether personally or on behalf of an entity (“you”) and Coaching by Viktor
    ("Coaching by Viktor", “we”, “us”, or “our”), concerning your access to and
    use of the https://www.coachingbyviktor.com website as well as any other
    media form, media channel, mobile website or mobile application related,
    linked, or otherwise connected thereto (collectively, the “Site”). You agree
    that by accessing the Site, you have read, understood, and agreed to be
    bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE
    TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU
    MUST DISCONTINUE USE IMMEDIATELY. <br /><br />
    Supplemental terms and conditions or documents that may be posted on the
    Site from time to time are hereby expressly incorporated herein by
    reference. We reserve the right, in our sole discretion, to make changes or
    modifications to these Terms of Use at any time and for any reason. We will
    alert you about any changes by updating the “Last updated” date of these
    Terms of Use, and you waive any right to receive specific notice of each
    such change. It is your responsibility to periodically review these Terms of
    Use to stay informed of updates. You will be subject to, and will be deemed
    to have been made aware of and to have accepted, the changes in any revised
    Terms of Use by your continued use of the Site after the date such revised
    Terms of Use are posted. <br /><br />
    The information provided on the Site is not intended for distribution to or
    use by any person or entity in any jurisdiction or country where such
    distribution or use would be contrary to law or regulation or which would
    subject us to any registration requirement within such jurisdiction or
    country. Accordingly, those persons who choose to access the Site from other
    locations do so on their own initiative and are solely responsible for
    compliance with local laws, if and to the extent local laws are applicable.
    <br /><br />
    The Site is intended for users who are at least 18 years old. Persons under
    the age of 18 are not permitted to use or register for the Site.
    <br /><br />
    <strong>USER REPRESENTATIONS</strong><br />
    By using the Site, you represent and warrant that: (1) you have the legal
    capacity and you agree to comply with these Terms of Use; (2) you are not a
    minor in the jurisdiction in which you reside; (3) you will not access the
    Site through automated or non-human means, whether through a bot, script or
    otherwise; (4) you will not use the Site for any illegal or unauthorized
    purpose; and (5) your use of the Site will not violate any applicable law or
    regulation.<br /><br />
    If you provide any information that is untrue, inaccurate, not current, or
    incomplete, we have the right to suspend or terminate your service and
    refuse any and all current or future use of the Site (or any portion
    thereof).<br /><br />
    <strong>PRODUCTS</strong><br />
    All products are subject to availability. We reserve the right to
    discontinue any products at any time for any reason. Prices for all products
    are subject to change.<br /><br />
    <strong>PURCHASES AND PAYMENT</strong><br />
    We accept the following forms of payment: Visa and MasterCard. All prices
    indicated on the Rosengren Fitness Consulting AB’s site are in Swedish
    kronor (SEK) and include VAT. Payment for the purchase of the coaching
    service is deducted when you have accepted the payment after signing up for
    the online coaching service subscription.
    <br /><br />
    The subscription is subject to recurring charges. By accepting the terms as
    a client of Rosengren Fitness Consulting AB, you consent to our charging
    your payment method on a recurring basis without requiring your prior
    approval for each recurring charge, until such time as you cancel the
    applicable order.
    <br /><br />
    All charges are facilitated through Stripe Checkout, a third-party payment
    processing service provided by Stripe Payments Europe, Limited. Clients must
    agree and are subject to the Stripe Checkout Terms of Service
    (https://stripe.com/en-se/checkout/legal).
    <br /><br />
    <strong>RIGHT OF WITHDRAWAL</strong><br />
    By accepting the Terms and Conditions, you agree that the online coaching
    services are specifically produced to your specification and have been given
    a clear personalised character. Therefore, with accordance to the Swedish
    Consumer Agency, there is no right of withdrawal once your coaching services
    have been delivered. The cancellation period is the current month with a
    minimum of one week’s notice before the next monthly billing period. If the
    notice of cancellation is made within a week of the next monthly billing
    period, then the cancellation period is the current month plus 1 month.
    <br /><br />
    <strong>PRIVACY POLICY</strong><br />
    We care about data privacy and security. Please review our Privacy Policy:
    <a v-b-modal.modal-2><u>Privacy Policy</u></a
    >. By using the Site or the Marketplace Offerings, you agree to be bound by
    our Privacy Policy, which is incorporated into these Terms of Use. Please be
    advised the Site and the Marketplace Offerings are hosted in Sweden. If you
    access the Site or the Marketplace Offerings from any other region of the
    world with laws or other requirements governing personal data collection,
    use, or disclosure that differ from applicable laws in Sweden, then through
    your continued use of the Site, you are transferring your data to Sweden,
    and you expressly consent to have your data transferred to and processed in
    Sweden. <br /><br />
    <strong>GOVERNING LAW</strong><br />
    These conditions are governed by and interpreted following the laws of
    Sweden, and the use of the United Nations Convention of Contracts for the
    International Sales of Goods is expressly excluded. If your habitual
    residence is in the EU, and you are a consumer, you additionally possess the
    protection provided to you by obligatory provisions of the law in your
    country of residence. Rosengren Fitness Consulting AB and yourself both
    agree to submit to the non-exclusive jurisdiction of the courts of Sweden,
    which means that you may make a claim to defend your consumer protection
    rights in regards to these Conditions of Use in Sweden, or in the EU country
    in which you reside.
    <br /><br />
    <strong>DISPUTE RESOLUTION</strong><br />
    The European Commission provides an online dispute resolution platform,
    which you can access here: https://ec.europa.eu/consumers/odr. If you would
    like to bring this subject to our attention, please contact us.
    <br /><br />
    <strong>CORRECTIONS</strong><br />
    There may be information on the Site that contains typographical errors,
    inaccuracies, or omissions that may relate to the Marketplace Offerings,
    including descriptions, pricing, availability, and various other
    information. We reserve the right to correct any errors, inaccuracies, or
    omissions and to change or update the information on the Site at any time,
    without prior notice.<br /><br />
    <strong>CONTACT US</strong><br />
    In order to resolve a complaint regarding the Site or to receive further
    information regarding use of the Site, please contact us at:
    <br /><br />
    Coaching by Viktor<br />
    von Platens gränd 1<br />
    545 34 Töreboda, Västra Götaland<br />
    Sweden<br />
    Phone: 073-939 64 00<br />
    Email: info@coachingbyviktor.com<br />

    <br />
  </div>
</template>

<script>
export default {
  name: "termsconditions",
};
</script>
