<template>
  <div id="contactform">
    <b-row align-h="center" class="header-section text-center bg-color">
      <b-col cols="8">
        <div class="header">Hör av Dig</div>
        <!-- <div
          style="font-size: 1em; padding-bottom: 1.5em; position: relative; left: 50%; transform: translateX(-50%); width: 75%;"
        >
          To discuss the services that I can offer you, contact me directly
          using the contact methods on the left or fill out the form to book a
          consultation or ask a question!
        </div> -->
      </b-col>
    </b-row>
    <b-row
      align-h="center"
      align-v="center"
      class="bg-color body-section text-center"
    >
      <b-col cols="10" lg="4" offset-lg="0" order-lg="1" order="2">
        <div class="subheader">
          Har du frågor?
        </div>
        <div class="text-font questions-description">
          Om du har frågor är du välkommen att kontakta mig per telefon, 
          email eller Instagram DM så återkopplar jag så snart som möjligt. 
        </div>
        <b-img
          class="contact-icon"
          fluid
          alt="mail icon"
          :src="require('../assets/icons/mail-thin.svg')"
        ></b-img>
        <div class="contact-detail text-font">
          info@coachingbyviktor.com
        </div>
        <b-img
          class="contact-icon"
          fluid
          alt="mobile icon"
          :src="require('../assets/icons/mobile-thin.svg')"
        ></b-img>
        <div class="contact-detail text-font">
          073-939 64 00
        </div>
        <b-img
          class="contact-icon"
          fluid
          alt="instagram icon"
          :src="require('../assets/icons/ig-thin.svg')"
        ></b-img>
        <div class="contact-detail text-font">
          @coachingbyviktor
        </div>
      </b-col>
      <!-- <b-col
        cols="10"
        lg="5"
        offset-lg="1"
        order-lg="2"
        order="1"
        style="background-color:#032129"
        class="form-col"
      >
        <div class="form-container">
          <form class="kwes-form" :action="kwesAction">
            <b-row class="form-top" align-h="center">
              <b-col md="5">
                <div class="inline-form input-div">
                  <div>
                    <h5>Name</h5>
                    <input
                      class="input"
                      type="text"
                      name="name"
                      rules="required"
                    />
                  </div>
                </div>
              </b-col>
              <b-col md="5">
                <div class="inline-form input-div">
                  <div>
                    <h5>Email</h5>
                    <input
                      class="input"
                      type="text"
                      name="email"
                      rules="required_if:contact-method,email"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col md="5">
                <div class="inline-form input-div">
                  <div>
                    <h5>Phone</h5>
                    <input
                      class="input"
                      type="text"
                      name="phone"
                      rules="required_if:contact-method,phone"
                    />
                  </div>
                </div>
              </b-col>
              <b-col md="5">
                <div class="inline-form input-div">
                  <div>
                    <h5>Contact method</h5>
                    <select
                      class="input-select"
                      name="contact-method"
                      rules="required"
                    >
                      <option style="display:none" />
                      <option value="email">Email</option>
                      <option value="phone">Phone</option>
                    </select>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col md="10">
                <div class="input-div-message inline-form">
                  <div>
                    <h5>Message</h5>
                    <textarea class="input" name="message" rules="required" />
                  </div>
                </div>
              </b-col>
            </b-row>
            <div class="agreement">
              <input type="checkbox" id="terms" name="terms" rules="required" />
              <label for="terms">
                &ensp; I agree to the
                <a v-b-modal.modal-2><u>Privacy Policy</u></a>
              </label>
            </div>
            <b-button pill type="submit" class="btn-formsubmit text-nowrap">
              Submit
            </b-button>
          </form>
        </div>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      selected: "",
      kwesAction: process.env.VUE_APP_KWES_ACTION_LINK,
      options: [
        { text: "Strength", value: "strength" },
        { text: "Weightloss", value: "weightloss" },
        { text: "Nutrition", value: "nutrition" },
        { text: "Other", value: "other" },
      ],
    };
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://kwes.io/v2/kwes-script.js");
    document.head.appendChild(externalScript);
    this.changeInpuOnFocus();
  },
  methods: {
    changeInpuOnFocus() {
      const inputs = document.querySelectorAll(".input, .input-select");

      inputs.forEach((input) => {
        input.addEventListener("focus", addcl);
        input.addEventListener("blur", remcl);

        function addcl() {
          let parent = this.parentNode.parentNode;
          parent.classList.add("focus");
        }

        function remcl() {
          let parent = this.parentNode.parentNode;
          if (this.value == "") {
            parent.classList.remove("focus");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.agreement {
  font-size: 14px;
  padding: 1em 0 0.5em 0;
  color: white;
}

.agreement label {
  text-indent: 8px;
}

.subheader {
  color: #c57b35;
  padding-bottom: 25px;
}

.questions-description {
  padding-bottom: 40px;
}

.form-top {
  padding-top: 40px;
}

.header-section {
  padding-top: 70px;
}

.body-section {
  padding-bottom: 70px;
}

.contact-detail {
  padding-top: 16px;
  padding-bottom: 40px;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  resize: none;
}

.bg-color {
  background-color: #f9fafa;
}

.header:after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 15%;
  border: 2px solid black;
  display: block;
  border-color: #c57b35;
}

.contact-icon {
  height: 36px;
}

.bg-consultation-header {
  /* color: rgba(0, 0, 0, 0.75); */
  color: black;
  text-align: center;
  font-size: 1.6em;
  padding-top: 0.6em;
  /* padding-top: 1em;
  margin-left: -2.5em; */
}

.bg-askquestion-header {
  text-align: center;
  text-transform: lowercase;
}

.checkbox-group {
  margin-bottom: -0.2em;
  color: #555;
}

.form-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

/* TEST WHEN FORM IS LIVE TO SEE PLACEMENT
.form-col {
  padding-bottom: 40px;
} */

.form-col {
  margin-bottom: 40px;
}

.icon {
  width: 1.7em;
  margin-right: 1em;
}

.icon-group {
  padding-bottom: 0.6em;
}

form {
  width: 100%;
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 0.95rem;
  color: white;
}

.input-select {
  position: absolute;
  width: 100%;
  height: 130%;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  background: none;
  margin-top: -0.2rem;
  padding: 0 0.35rem;
  /* margin-bottom: 0.5rem;
  margin-top: -0.2rem;
  padding-left: 0.35rem; */
  font-size: 1rem;
  color: white;
}

.input-select:valid {
  border: none;
}

.input-div {
  position: relative;
  display: grid;
  grid-template-columns: 100% 00%;
  margin: 0 3px;
  padding: 25px 0 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.input-div-message {
  position: relative;
  display: grid;
  grid-template-columns: 100% 00%;
  margin: 20px 3px;
  padding: 50px 0;
  border-bottom: 2px solid #d9d9d9;
}

.input-div > div {
  position: relative;
  height: 15px;
}

.input-div > div > h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #d9d9d9;
  font-size: 15px;
  transition: 0.3s;
}

.input-div-message > div > h5 {
  position: absolute;
  left: 10px;
  top: 10%;
  color: #d9d9d9;
  font-size: 15px;
  transition: 0.3s;
}

.input-div:before,
.input-div:after,
.input-div-message:before,
.input-div-message:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #c57b35;
  transition: 0.4s;
}

.input-div:before,
.input-div-message:before {
  right: 50%;
}

.input-div:after,
.input-div-message:after {
  left: 50%;
}

.input-div.focus:before,
.input-div.focus:after,
.input-div-message.focus:before,
.input-div-message.focus:after {
  width: 50%;
}

.input-div.focus > div > h5,
.input-div-message.focus > div > h5 {
  top: -15px;
  font-size: 12px;
}

.input:valid {
  border: none;
}

/* .btn {
  font-family: "Montserrat", sans-serif;
  display: block;
  margin: 0 auto;
  width: 40%;
  height: 50px;
  background-color: white;
  border-radius: 0px;
  border-color: #c57b35;
  transition: 0.5s;
  text-transform: uppercase;
}

.btn:hover {
  background-color: #c57b35;
  border: none;
  color: white;
} */

.btn-formsubmit {
  padding: 15px 30px;
  background-color: #c57b35;
  border: none;
  font-weight: 600;
  transition: 0.5s;
  text-transform: uppercase;
  color: white;
  font-size: 14px;
}

.btn-formsubmit:hover {
  border: 1px solid #c57b35;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  letter-spacing: 1px;
}

/* @media screen and (max-width: 1024px) {
  .direct-contact-border {
    width: 90%;
  }
} */
</style>
