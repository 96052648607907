import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Plans from "./views/Plans.vue";
import HowItWorks from "./views/HowItWorks.vue";
import Success from "./views/Success.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
      // } else if (to.hash) {
      //   return {
      //     selector: to.hash,
      //   };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/plans",
      name: "plans",
      component: Plans,
    },
    {
      path: "/howitworks",
      name: "howitworks",
      component: HowItWorks,
    },
    {
      path: "/success",
      name: "success",
      component: Success,
    },
  ],
});
