<template>
  <div>
    <b-navbar
      :class="{ change_color: scrollPosition > 50 }"
      class="fixed-top nav-bg"
      toggleable="md"
      type="dark"
    >
      <b-navbar-brand tag="h1" v-scroll-to="{ el: '#headbanner' }" class="mb-0"
        ><b-button
          squared
          variant="outline"
          class="logo"
          :class="{ scrolled_logo: scrollPosition > 50 }"
          >
          <img src="../assets/cbv_logo.svg" />
          </b-button
        >
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto text-center">
          <b-nav-item v-scroll-to="{ el: '#services', offset: -70 }"
            >Utbud</b-nav-item
          >
          <b-nav-item v-scroll-to="{ el: '#pricinginfo', offset: -70 }"
            >Priser
          </b-nav-item>
          <!-- <b-nav-item v-scroll-to="{ el: '#clients', offset: -70 }"
            >Clients
          </b-nav-item> -->
          <b-nav-item v-scroll-to="{ el: '#contactform', offset: -70 }"
            >Kontakt</b-nav-item
          >
          <b-nav-item v-scroll-to="{ el: '#aboutus', offset: -80 }"
            >Om Mig
          </b-nav-item>
          <!-- <b-nav-item class="contact-nav">
            <b-button
              class="btn-contact"
              v-scroll-to="{ el: '#contactform', offset: -50 }"
              >Book Consultation</b-button
            >
          </b-nav-item> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      scrollPosition: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
      if (width > 768) {
        this.scrollPosition = window.scrollY;
        // } else if (width > 768) {
        //   this.scrollPosition = window.scrollY * 1.5;
      } else if (width <= 768) {
        this.scrollPosition = window.scrollY * 1.5;
      }
      // if X size is 1024, this.scrollPosition = window.scrollY*1.5
    },
  },
};
</script>

<style scoped>
.navbar {
  min-height: 80px;
  /* border-bottom: 1px solid white; */
  margin-left: 50px;
  margin-right: 50px;
}

/* .btn-contact {
  background-color: #c57b35;
  border-radius: 25px;
  border: none;
  transform: translateY(20%);
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  white-space: nowrap;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
} */

/* .btn-contact:hover {
  border: none;
  background-color: rgba(255, 255, 255);
  color: #c57b35;
} */

.btn-contact:focus {
  border: none;
  outline: none;
  background-color: #c57b35;
  color: rgba(255, 255, 255);
}

.btn-outline-light:hover {
  border-color: white;
}

.btn-outline:hover {
  color: white;
}

.contact-nav {
  margin-left: 40px;
  margin-right: 50px;
}

p {
  margin-bottom: 0;
}

.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  /* padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em; 
  border-width: 0.15em;
  border-color: white;
  color: #c57b35;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: 0.5s ease;*/
}

.under-logo {
  font-size: 0.35em;
}

.nav-item {
  align-self: flex-end;
  /* width: 8em;
  font-family: "Oswald", sans-serif; */
  width: 8em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  white-space: nowrap;
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: #c57b35 !important;
}

.nav-bg {
  background-color: transparent;
  transition: 0.5s ease;
}

.change_color {
  background-color: rgb(13, 13, 13);
  margin-left: 0em;
  margin-right: 0em;
  padding-left: 3em;
  padding-right: 3em;
}

.scrolled_logo {
  font-size: 0.8em;
  /* padding-left: 1.6em;
  padding-right: 1.6em;
  padding-top: 0.4em;
  padding-bottom: 0.4em; */
}

@media screen and (max-width: 1024px) {
  .nav-item {
    /* width: 5.5em; */
    width: 5.5em;
  }
  .contact-nav {
    margin-left: 30px;
  }
  .change_color {
    padding-right: 3.5em;
  }
}

@media screen and (max-width: 840px) {
  .navbar {
    min-height: 40px;
  }
  .logo {
    font-size: 0.8em;
    padding-left: 1.6em;
    padding-right: 1.6em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
  .contact-nav {
    margin-left: 10px;
  }
  .btn-contact {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
