<template>
  <div id="headbanner">
    <div class="banner-bg">
      <b-img
        class="bannerimage"
        :src="image"
        fluid
        alt="Responsive image"
      ></b-img>
    </div>
    <b-container fluid class="slogan-container">
      <b-row>
        <b-col md="5" cols="5" offset-md="6" offset="6">
          <div class="slogan">
            Online Personlig Träning
          </div>
          <div class="subslogan">
            Förser dig med verktygen du behöver för att nå dina mål
          </div>
          <div class="buttons">
            <b-button
              squared
              variant="light"
              class="btn-responsive text-nowrap"
              v-scroll-to="{ el: '#pricinginfo' }"
              >Börja nu</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Banner from "@/assets/banner.jpg";

export default {
  name: "HeadBanner",
  data() {
    return {
      image: Banner,
    };
  },
};
</script>

<style scoped>
#headbanner {
  position: relative;
}

.banner-bg {
  background-color: black;
}

.slogan-container {
  top: 38%;
  position: absolute;
  color: rgba(255, 255, 255, 0.904);
  opacity: 0.9;
}

.slogan {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  /* font-size: 3.2em; */
  font-size: 48px;
  line-height: 1.2em;
}

.subslogan {
  font-family: "Montserrat", sans-serif;
  padding-top: 1.2em;
  font-weight: 500;
  font-size: 24px;
}

.button-container {
  bottom: 10%;
  position: absolute;
}

.buttons {
  font-family: "Montserrat", sans-serif;
  padding-top: 2.1em;
}

.btn-responsive {
  border-color: transparent;
  transition: 0.5s ease;
  text-transform: uppercase;
  padding: 10px 32px;
  background-color: #c57b35;
  /* border: none; */
  color: white;
  font-size: 16px;
  letter-spacing: 0.1em;
}

.btn-responsive:hover {
  background-color: white;
  border-color: transparent;
  color: #c57b35;
}

.bannerimage {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  /* opacity: 0.4; */
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.75);
}

/* @media screen and (max-width: 1024px) {
  .btn-responsive {
    font-size: 1em;
  }
  .slogan {
    font-size: 2.8em;
  }
} */

@media screen and (max-width: 768px) {
  .btn-responsive {
    font-size: 0.8em;
    margin-top: 1em;
  }
  #headbanner img {
    height: 30em;
    object-fit: cover;
  }
  .slogan {
    font-size: 24px;
  }
  .subslogan {
    font-size: 16px;
  }
}

@media screen and (max-width: 566px) {
  .slogan {
    margin-left: -30px;
    margin-right: -25px;
  }
  .subslogan {
    margin-left: -30px;
    margin-right: -25px;
  }
  .buttons {
    margin-left: -30px;
    padding-top: 1em;
  }
}
</style>
