<template>
  <div id="howitworks-page">
    <nav-bar-pages />
    <small-banner pagename="How It Works" />
    <b-container fluid>
      <b-row
        align-v="center"
        align-h="center"
        class="infosection-top infosection"
      >
        <b-col cols="8" lg="4" class="faq-content text-center">
          <b-img
            class="image-large"
            fluid
            alt="booking illustration"
            :src="require('../assets/icons/booking-large.svg')"
          ></b-img>
        </b-col>
        <b-col cols="8" lg="4" offset-lg="1" class="faq-content">
          <div class="subheader hiw-subheader">
            Booking a Consultation
          </div>
          <div class="text-font">
            When booking a consultation you will see what times I have available
            in my calendar. Book a time that suits you and we’ll have a meeting
            wherein we’ll discuss your goals and how to achieve them. The
            consultation can be done through a phone call or a video meeting.
            Choose the option that you’re most comfortable with. If you’re not
            comfortable talking on the phone or having a video meeting then
            shoot me an email at info@coachingbyviktor.com and we will discuss
            your options. There are no obligations to sign up for a program
            after the consultation.
          </div>
        </b-col>
      </b-row>
      <b-row align-v="center" align-h="center" class="infosection">
        <b-col cols="8" lg="4" order-lg="1" order="2" class="faq-content">
          <div class="subheader hiw-subheader">
            Discussing Your Goals
          </div>
          <div class="text-font">
            During the consultation we will get acquainted and discuss all of
            the aspects that will lay the ground for how your program will look.
            Things such as current body weight, training history, food
            preferences, allergies and past/present injuries or illnesses will
            be necessary for me to know in order to make a nutrition program
            that will be as sustainable and enjoyable as possible for you to
            adhere to. If you are getting the full coaching option or the
            training program I will also want to know what gym equipment if any,
            you have access to so that I can make your training program specific
            to your resources. After the consultation you will have a good idea
            of what your program will look like and we will go through in detail
            how everything works so that everything will be easy and smooth.
          </div>
        </b-col>
        <b-col
          cols="8"
          lg="4"
          offset-lg="1"
          order-lg="2"
          order="1"
          class="faq-content text-center"
        >
          <b-img
            class="image-large"
            fluid
            alt="connversation illustration"
            :src="require('../assets/icons/conversation-large.svg')"
          ></b-img>
        </b-col>
      </b-row>
      <b-row align-v="center" align-h="center" class="infosection">
        <b-col cols="8" lg="4" class="faq-content text-center">
          <b-img
            class="image-large"
            fluid
            alt="mail illustration"
            :src="require('../assets/icons/mail-large.svg')"
          ></b-img>
        </b-col>
        <b-col cols="8" lg="4" offset-lg="1" class="faq-content">
          <div class="subheader hiw-subheader">
            Receiving Your Program
          </div>
          <div class="text-font">
            If after the consultation you decide you want to work with me and go
            through with the purchase of a program I will start working. Within
            72 hours you will receive the program you decided on as a pdf-file
            in your email inbox. We are currently working on a new project to
            deliver programs through an app so stay tuned!
          </div>
        </b-col>
      </b-row>
      <b-row align-v="center" align-h="center" class="infosection">
        <b-col cols="8" lg="4" order-lg="1" order="2" class="faq-content">
          <div class="subheader hiw-subheader">
            Ongoing Support &amp; Analysis
          </div>
          <div class="text-font">
            Every week I will want you to check in with me and update me on how
            it is going. If you’re doing a weight loss or weight gain program I
            will want to know your fasted morning weight every saturday or
            sunday so that I can make any adjustments to your program if results
            are not in accordance with what we expect or aim for. You are of
            course welcome to contact me at any time if you have questions,
            unforeseen changes happen or if you want any on the spot adjustments
            made for things to work better for you.
          </div>
        </b-col>
        <b-col
          cols="8"
          lg="4"
          offset-lg="1"
          order-lg="2"
          order="1"
          class="faq-content text-center"
        >
          <b-img
            class="image-large"
            fluid
            alt="chatting illustration"
            :src="require('../assets/icons/chatting-large.svg')"
          ></b-img>
        </b-col>
      </b-row>
      <b-row class="faq-container">
        <b-col>
          <b-row align-h="center">
            <b-col cols="8" class="faq-header text-center">
              Frequently Asked <br />Questions
            </b-col>
          </b-row>
          <b-row align-h="center" class="faq-content">
            <b-col cols="8">
              <faq
                v-for="question in questions"
                :key="question.id"
                :question="question"
              />
              <hr />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Faq from "../components/Faq.vue";
import NavBarPages from "../components/NavBar-Pages.vue";
import SmallBanner from "../components/SmallBanner.vue";

export default {
  name: "plans",
  components: {
    SmallBanner,
    NavBarPages,
    Faq,
  },
  data() {
    return {
      questions: [
        {
          id: 1,
          question: "How do I cancel my monthly plan?",
          answer:
            "Email me at info@coachingbyviktor.com and I will terminate your subscription and send you a confirmation email of the cancellation. There is however a one week (seven days) notice. For example, if your plan renews on the 20th of every month, the latest cancellation notice is on the 13th. This is because I would have started work on your program within a week before sending it to you.",
        },
        {
          id: 2,
          question: "How often will my nutrition plan be updated?",
          answer:
            "Depending on your preferences, it will differ if you prefer full recipes or a simple nutrition plan with only ingredient lists. If you choose to receive full recipes, in some cases in the beginning, it can be weekly as I get to learn what type of foods you enjoy. If you choose to receive a simple nutrition plan, then in most cases the plan will be updated monthly unless you request for an update sooner. This of course will also be affected depending on your progress.",
        },
        {
          id: 3,
          question: "How often will my training program be updated?",
          answer:
            "In most cases training programs will be updated once a month unless unforeseen circumstances call for intervention (injuries, sickness, limited access to gym etc). Another example of when a training program will be updated more frequently is if an athlete is getting close to a competition and is trying to optimize performance on a given day.",
          answer2:
            "Training programs might also be updated less frequently than once a month if the client is doing particularly well on a given program or during a particular training cycle. I will in all cases consider what is best at any given time for the client in order to optimize results.",
        },
        {
          id: 4,
          question:
            "What will be the main form of contact during the time I am a client?",
          answer:
            "We will during the initial consultation decide on a method of keeping in contact that suits you. Some examples of these methods may include whatsapp messaging, sms or phone calls.",
        },
        {
          id: 5,
          question:
            "What if I am intolerant or have allergies to certain types of food?",
          answer:
            "If you have food allergies or intolerances it is important that you tell me during the consultation so that I can exclude any such foods from your nutrition program. ",
        },
        {
          id: 6,
          question:
            "Why are there 3 days not accounted for from the day I make my payment to the day I receive my program?",
          answer:
            "During these three days I will be working on and setting up your program. Since all of the programs I make are personalized and tailored to your preferences the initial work up requires some extra time. You will not receive a program previously used by someone else but an original program made only for you, hence the waiting time.",
          answer2:
            "You will always get the full month of programming and support you paid for. If it took three days from when you paid until you got your program, the month of services starts on the day you received your program, not on the day you made your payment. ",
        },
        {
          id: 7,
          question: "Can I get in contact with you at any time as a client?",
          answer:
            "Yes! Generally, I will reply to messages within 2 hours given the messages are sent during my waking hours.",
        },
        {
          id: 8,
          question: "Can I pause my subscription?",
          answer:
            "Yes you can put your subscription on hold. Just notify me using the established contact method and I will pause your account. This means you will not have to go through the initial consultation again when you’re ready to continue. You will not be charged during the time your account is paused.",
        },
        {
          id: 9,
          question:
            "Is there a minimum commitment time for the monthly subscriptions?",
          answer:
            "No, you can cancel your monthly subscription at any time you want, but keep in mind that there is a one week (seven days) cancellation notice. I do however, recommend clients to stay on for at least 2 months to provide sufficient time to see any real results.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.faq-container {
  padding-top: 70px;
  background-color: #f9fafa;
}

.faq-content {
  padding-bottom: 70px;
}

.faq-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 48px;
  /* 40px seems to be ideal? */
  color: #2b2b2b;
  padding-bottom: 40px;
}

.hiw-subheader {
  padding-bottom: 16px;
}

hr {
  margin-top: 15px;
}

.infosection-top {
  padding-top: 140px;
}

.infosection {
  padding-bottom: 70px;
}

/* .infosection .col {
  padding-bottom: 35px;
} */

/* .infosection .image-large {
  padding-bottom: 35px;
} */

@media screen and (max-width: 992px) {
  .hiw-subheader {
    text-align: center;
  }
}
</style>
