<template>
  <div>
    This privacy policy will explain how Coaching by Viktor ("Company", "we",
    "us", and "our") uses the personal data we collect from you when you use our
    website.<br /><br />
    Topics:<br />
    <ul>
      <li>What data do we collect?</li>
      <li>How do we collect your data?</li>
      <li>How will we use your data?</li>
      <li>How will we share your data?</li>
      <li>How do we store your data?</li>
      <li>How long do we store your data?</li>
      <li>Marketing</li>
      <li>What are your data protection rights?</li>
      <li>What are cookies?</li>
      <li>Privacy policies of other websites</li>
      <li>Use by Minors</li>
      <li>Changes to our privacy policy</li>
      <li>How to contact us</li>
    </ul>
    <br /><br />
    <strong>What data do we collect?</strong><br />
    We collect the personal information you voluntarily provide, which may
    include following data: <br />
    <ul>
      <li>
        Personal identification information such as your first and last name,
        email address, phone number and year of birth
      </li>
      <li>
        Body anthropometric information such as body statistics, photos,
        progress as well as genetic, biometric and health data
      </li>
      <li>Preferences related to food and training activities</li>
    </ul>
    <strong>How do we collect your data?</strong><br />
    You directly provide us with the data we collect. We collect data and
    process data when you:<br />
    <ul>
      <li>Register online or place an order for any of our services.</li>
      <li>
        Voluntarily complete a client survey or provide feedback on any of our
        forms or via email.
      </li>
      <li>
        Complete a consultation either through phone call communications or
        completing forms.
      </li>
      <li>
        Update us through email, direct text messaging or phone call
        communication regarding changes in your body for the coaching service.
      </li>
    </ul>
    <strong>How will we use your data?</strong><br />
    We collect your data so that we can:<br />
    <ul>
      <li>Process your order and manage your account.</li>
      <li>
        Provide and maintain individualised and tailored nutrition and training
        plans.
      </li>
      <li>
        Keep in contact with you by email, telephone calls, SMS, or other
        equivalent forms of electronic communication, in order to get updates
        and feedback on your progress in both nutrition and training.
      </li>
      <li>
        Analyse the information collected from updates to be used in order to
        make informed decisions on how to proceed with your nutrition and/or
        training service.
      </li>
      <li>Attend and manage your requests to us.</li>
    </ul>
    <strong>How will we share your data?</strong><br />
    We provide information to other service providers who perform services for
    us or on our behalf and require access to such information to do that work.
    This includes payment processing, email delivery and hosting services.
    <br />
    <br />
    We do not and will never sell your data to any 3rd party service providers.
    We may disclose your personal information if we are required by law to do
    so.
    <br /><br />
    <strong>How do we store your data?</strong><br />
    We have implemented appropriate technical and organisational security
    measures designed to protect the security of any personal information we
    process. We securely store your data locally, on hardware encrypted servers.
    <br /><br />
    <strong>How long do we store your data?</strong><br />
    We will retain your personal data only for as long as is necessary for the
    purposes set out in this Privacy Policy unless a longer retention period is
    required or permitted by law (such as tax, accounting or other legal
    requirements to resolve disputes, and enforce our legal agreements and
    policies).
    <br /><br />
    When we have no ongoing legitimate business need to process your personal
    information, we will either delete or anonymize it, or, if this is not
    possible (for example, because your personal information has been stored in
    backup archives), then we will securely store your personal information and
    isolate it from any further processing until deletion is possible.
    <br /><br />
    <strong>Marketing</strong><br />
    We will not send you information about products or services of ours or of
    any other companies.
    <br /><br />
    <strong>What are your data protection rights? </strong> <br />
    We would like to make sure you are fully aware of all of your data
    protection rights. Every user is entitled to the following: <br /><br />
    <ul class="no-bullets">
      <li>
        <strong>The right to access</strong> – You have the right to request us
        for copies of your personal data.
      </li>
      <br />
      <li>
        <strong>The right to rectification</strong> – You have the right to
        request that we correct any information you believe is inaccurate. You
        also have the right to request us to complete the information you
        believe is incomplete.
      </li>
      <br />
      <li>
        <strong>The right to erasure</strong> – You have the right to request
        that we erase your personal data, under certain conditions.
      </li>
      <br />
      <li>
        <strong>The right to restrict processing</strong> – You have the right
        to request that we restrict the processing of your personal data, under
        certain conditions.
      </li>
      <br />
      <li>
        <strong>The right to object to processing</strong> – You have the right
        to object to our processing of your personal data, under certain
        conditions.
      </li>
      <br />
      <li>
        <strong>The right to data portability</strong> – You have the right to
        request that we transfer the data that we have collected to another
        organization, or directly to you, under certain conditions.
      </li>
    </ul>
    If you make a request, we have one month to respond to you. If you would
    like to exercise any of these rights, please contact us at our email:
    info@coachingbyviktor.com <br /><br />
    <strong>Cookies</strong><br />
    Cookies are small data files store on your computer or mobile device when
    you visit a website to collect standard Internet log information and visitor
    behavior information. Cookies set by the website owner are called first
    party cookies that are categorized as "essential" or "strictly necessary"
    cookies. Cookies set by parties other than the website owner are called
    "third party cookies". Third party cookies enable third party features or
    functionality to be provided on or through the website (e.g. like
    advertising, interactive content and analytics).

    <br /><br />
    We use first and third party cookies to improve your experience while you
    navigate through the website. First party cookies are required for technical
    reasons in order for our Website to operate. We also use third party cookies
    that help us analyze and understand how you use this website. These cookies
    will be stored in your browser only with your consent. You have the right to
    decide whether to accept or reject cookies. But rejecting some of these
    cookies may have an effect on your browsing experience. You have the right
    to control your preferences of the use of cookies in the footer of this
    website under the link "Cookie Preferences".
    <br /><br />

    <strong>Privacy policies of other websites</strong><br />
    Coaching by Viktor contains links to third party websites, namely KwesForms
    and Stripe. Our privacy policy applies only to our website, so if you click
    on a link to another website, you should read their privacy policy.
    <br /><br />
    <strong>Use by Minors</strong><br />
    We do not knowingly solicit data or market to children under the age of 13.
    If you think that your child provided this kind of information on our
    website, we strongly encourage you to contact us immediately and we will do
    our best efforts to promptly remove such information from our records. If
    you become aware of any data we have have collected from children under age
    13, please contact us at info@coachingbyviktor.com <br /><br />
    <strong>Changes to our privacy policy</strong><br />
    Coaching by Viktor keeps its privacy policy under regular review and places
    any updates on this web page. This privacy policy was last updated on 30
    January 2021.
    <br /><br />
    <strong>How to contact us</strong><br />
    If you have any questions about our privacy policy, the data we hold on you,
    or you would like to exercise one of your data protection rights, please do
    not hesitate to contact us. Email us at:<br />
    info@coachingbyviktor.com
    <br />
  </div>
</template>

<script>
export default {
  name: "privacypolicy",
};
</script>

<style scoped>
ul.no-bullets {
  list-style-type: none; /* Remove bullets */
}
</style>
